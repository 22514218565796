
.ant-layout-header{
    height: 70px;
    line-height: 70px;
    padding: 0px;
    width: 100%;
    z-index: 9;
    background-color: whitesmoke;
    position: fixed;
    left: 0px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}
.logo{
    width: 250px;
    height: 70px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 1rem;
    background-color: transparent;
    transition: all 0.2s ease 0s;
}
.logo img {
    width: 200px;
    height: 55px;
}
.trigger-container{
    width: calc(100% - 250px);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    transition: all 0.2s ease 0s;
}
.trigger-icon{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 16px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: all 0.3s ease 0s;
}
.trigger-icon span{
    display: flex;
    justify-content: center;
    align-items: center;
}
.trigger {
    font-size: 22px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: rgb(213, 168, 71);
}