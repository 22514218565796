.verifyKyc{
    width: 100%;
    background-color: #fafafa;
}
.verifyKyc form {
    padding-left: 275px;
    margin-top: 112px;
}
.verifyKyc form .headerInner{
    padding-left: 24px;
}
.verify-info-container{
    row-gap: 24px;
    display: flex;
    flex-direction: column;
}

.verifyKyc .sc-line-midle{
    width: 100%;
    height: 1px;
    background-color: rgb(193, 193, 193);
    transform: rotate(0deg);
    margin: 7px auto;
}
.verifyKyc .sc-title{
    font-weight: bold;
    font-size: 18px;
    /* padding-bottom: 25px; */
    color: #72849a;
    line-height: 1.8;
}
.verifyKyc .sc-col-value{
    color: #000;
    font-size: 14px ;
    font-weight: bold;
}