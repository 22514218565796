.site-layout .ant-layout-sider.ant-layout-sider-dark {
    background-color: #fff !important;
    display: block;
    flex: 0 0 250px;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
    height: calc(100vh - 70px);
    z-index: 12;
    position: fixed;
    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
    margin-top: 70px;
    overflow-x: hidden;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    display: block;
    flex: 0 0 80px;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}
.ant-menu {
    box-sizing: border-box;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum";
    margin: 0;
    padding: 0;
    color: hsla(0, 0%, 43.9%, 0.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #fff !important;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
/* .ant-menu li span[role ='img']:hover , .ant-menu li span[role ='img'] + span:hover{
    color: #d5a847 !important;
} */
.ant-layout-sider-children {
    padding-top: 35px;
}
.ant-menu-item-icon.anticon svg {
    font-size: 22px;
    font-weight: 500;
}

.ant-menu-submenu-arrow {
    color: hsla(0, 0%, 43.9%, 0.85);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(213,168,71,.1);
}
.ant-menu-item a{
    color: hsla(0, 0%, 43.9%, 0.85) !important;
}
.ant-menu-submenu-title:hover ,
.ant-menu-submenu-title li:focus ,
.ant-menu-item a:hover ,
.ant-menu-item a:focus
{
    color: #d5a847 !important;
}
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light li {
    font-size: 1rem;
    color: hsla(0, 0%, 43.9%, 0.85);
    padding-left: 24px;
    display: block;
}
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light li div.ant-menu-submenu-title{
    padding-left: 0 !important;
}
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light li:hover div ,
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light li:hover span{
    color: #d5a847 !important;

}
.ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #d5a847; 
}