.ant-btn.ant-btn-default{
    background-color: #d5a847;
    border: none;
    color: #fff;
    border-radius: 12px;
}
.ant-btn.ant-btn-default:hover{
    background-color: #d5a847;
    border: none;
    color: #fff;
    border-radius: 12px;
}
.support-head-title{
    float: left;
}

.support-head-button{
    float: right;
    font-size: 18px;
}

.support-table{
    margin-top: 70px;
}

.support-form{
    margin-top: -50px;
}
h4 {
    color: #000000 !important;
}