ul.ant-pagination > li:not(.ant-pagination-item-active , .ant-pagination-prev , .ant-pagination-next , .ant-pagination-options) {
    display: none;
  }
  ul.ant-pagination.ant-table-pagination  button.ant-pagination-item-link , li.ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active , .ant-pagination-item-active:hover{
    background-color: #d5a847;
    border-color: #d5a847;
  }
  .ant-pagination-item-active a{ 
  
    color: #fff !important;
  }
  .ant-pagination .ant-select {
    color: #72849a;
  }
  .ant-select-selector:hover , .ant-select-selector:focus{
    border-color: #d5a847 !important;  
  }
  /* li.ant-pagination-options .ant-select .ant-pagination-options-size-changer .ant-select-single .ant-select-show-arrow:hover {
  } */
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #dbddda;
    border-radius: 0.625rem;
    height: 2.6375rem;
    padding: 0 11px;
    justify-content: center;
    align-items: center;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(213,168,71,.1);
    color: #d5a847;
  }
  .ant-select-item .ant-select-item-option .ant-select-item-option-active{
    background-color: rgba(213,168,71,.1);
    color: #d5a847;
  }
  
  .ant-typography{
  
    margin-right: .8rem;
  }
  
  .ant-btn-icon-only.ant-btn-sm {
    width: 2rem;
    height: 2rem;
    font-size: .75rem;
    border-radius: 0.3rem;
    border-color: #d5a847;
    text-align: center;
  }
  .ant-btn-icon-only.ant-btn-sm span {
    align-items: center;
  }
  .ant-table , th.ant-table-cell{
    color: #707070 !important;
  }
  .ant-table-thead > tr > th {
    background-color: #fff !important;
  }
  .ant-tooltip-inner {
    max-width: 15.625rem;
    padding: 0.3125rem 0.625rem;
    color: #fff;
    text-align: left;
    text-decoration: none;
    background-color: #404040;
    border-radius: 0.625rem;
    box-shadow: 0 2px 8px hsl(0deg 0% 44% / 15%);
    min-height: auto;
  }
  main.ant-layout-content.site-layout-background {
    padding-left: 275px !important;
}
.admin-btn-action .btn-kyc{
  border-color: #ff6b72;
}
.admin-btn-action .btn-kyc svg{
  color: #ff6b72;
}