@import '~antd/dist/antd.css';

.site-layout .site-layout-background {
  background: #fff;
  margin-top: 70px;
}
.ant-btn svg {
  color: #d5a847;
}
input:hover{
  border-color: #d5a847 !important;
}
button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button span[role="img"] svg{
  color: rgba(0, 0, 0, .45) !important ;
  font-size: 20px;
}
button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button:hover{
  border: none;
  border-left: 1px solid #d5a847;
  background-color: #EDEDED;
  transition: all .6s;
}
.ant-menu-item-selected {
  color: #d5a847 !important;
}
.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected{
  color: #d5a847 !important;
}