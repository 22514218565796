.add-user{
    padding-left: 250px;
    width: 100%;
    margin-top: 70px;
    background: #fafafa;
}
.form_add-user{
    padding: 24px 24px 45px 50px;
    background: #fafafb;
    margin-top: 20px;
}
.form_add-user form label{
    color: #707070;
    font-weight: 600;
}
.form_add-user form input{
    outline: none !important;
}
.form_add-user form input:hover{
    border-color: #e6ba5a;
}
.ant-form-item .ant-row.ant-form-item-row .ant-col.ant-col-16.ant-form-item-control{
    min-width: 100% !important;
}
.header{
    box-shadow: 0 0 16px  rgba(0, 0, 0, .1);
}