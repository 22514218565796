.toolbar__Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    margin-top: 16px;
}
.toolbar__Wrapper .d-flex{
    display: flex;
    justify-content: end;
    align-items: center;
}
.toolbar__Wrapper .d-flex a{
    color: #fff;
}
.toolbar__Wrapper .d-flex > button {
    display: flex;
    margin-right: 16px;
    background-color: #d5a847;
    border-color: #d5a847;
    color: #fff;
    font-size: .875rem;
    padding: 0.5rem 1.25rem;
    line-height: 1.5;
    border-color: #dbddda;
    height: 2.5rem;
    border-radius: 0.625rem;
    cursor: pointer;
    border: 1px solid #d9d9d9;
}
.ant-input{
    padding: 0.6rem 1rem;
    line-height: 1.5;
    color: #707070;
    height: auto;
    border: 1px solid #dbddda;
    border-radius: 0.625rem;
    background-color: #fff;
}
.ant-input-search .ant-input-group .ant-input-group-addon button{
    border-radius: 0 2px 2px 0;
    height: 42px;
    width: 2.5rem;
}
.toolbar__Wrapper .anticon.anticon-plus svg{
    font-size: 22px;
}
