
.headerInner{
    height: 123px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    top: -43px;
    left: -50px;
    width: 105%;
}
.content-header{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    padding-right: 40px;
}
.content-header .title-header{
    font-size: 1.375rem;
    color: #707070;
    margin-bottom: 0;
}
.btn-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-header button{
    font-size: .875rem;
    padding: 0.5rem 1.25rem;
    height: 2.5rem;
    width: 80px;
    border-radius: 0.625rem;
    cursor: pointer;
    background-color: #d5a847;
    border-color: #d5a847;
    color: #fff;
    text-align: center;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-header button:focus{
    background-color: #d5a847;
    border-color: #d5a847;
    color: #fff;
}
.btn-header button:hover{
    background-color: #e6ba5a;
    border-color: #d5a847;
}
.btn-header > a.prevPage{
    background-color: transparent;
    border-color: #d5a847;
    color: #d5a847;
    width: 45px;
    border-radius: .625rem;
    height: 2.5rem;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.btn-header span svg{
    font-size: 22px;
}