.partAdd{
    width: 100%;
    padding: 95px 0 0 56px;
    margin-left: 235px;
    padding-top: 100px;
    background-color: #fafafa;
}
.form-details{
    padding-right: 24px;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
    height: 400px;
    background: none;
}