section.ant-layout.ant-layout-has-sider.site-layout{
    background-color: #fff;
}
.html-part{
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 275px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 70px;
}
.html-part .toolbar__Wrapper{
    padding-right: 24px;
}
.html-part .ant-table-wrapper{
    /* padding-left: 270px; */
    width: 100%;
}
.html-part .ant-spin-nested-loading{
    padding-right: 16px;
}